"use strict";

// const mockPrices = [
//   { id: "999", id: "999er Feingold", price: 0 },
//   { id: "916", id: "916er Gold", price: 0 },
//   { id: "900", id: "900er Gold", price: 0 },
//   { id: "750", id: "750er Gold", price: 0 },
//   { id: "585", id: "585er Gold", price: 0 },
//   { id: "333", id: "333er Gold", price: 0 }
// ];

var goldPrices = [];

function filterPriceForId(arr) {
  return function(id) {
    return arr.filter(function(elem) {
      return elem.id === id;
    })[0].price;
  };
}

function calcAmount(id) {
  return (
    filterPriceForId(goldPrices)(id) *
    $("#input-" + id)
      .val()
      .replace(",", ".")
  );
}

$(document).ready(function() {
  const BranchElem = $("section#branch");
  const HeaderElem = $("header");
  const ContentElem = document.getElementById("text-company");
  const PricesElem = document.getElementById("prices");
  const CalcElem = document.getElementById("calculator");
  const ContactElem = document.getElementById("contact-anchor");
  const ContactButtonElem = document.getElementById("button-send-contactdata");
  const ActPage = window.location.pathname.split("/").pop();
  const PricesTableElem = $("#prices-table tbody");

  $("#button-overlay-hide").click(function(e) {
    $("#overlay-info").hide();
  });

  if (PricesTableElem !== "undefined") {
    var tableHTML = "";

    fetch("https://api.goldankauf-turgay.de/articles")
      .then(function(result) {
        return result.json();
      })
      .then(function(data) {
        data.forEach(function(element) {
          if (element.id !== "base") {
            tableHTML =
              tableHTML +
              "<tr><td class='td-article'>" +
              (element.label === "999er Gold"
                ? "999er Feingold *"
                : element.label) +
              "</td><td class='td-price'>" +
              parseFloat(element.price).toFixed(2) +
              " €</td></tr>";
          }
        });
        PricesTableElem.html(tableHTML);
        goldPrices = data;
      })
      .catch(function(err) {
        console.log("ERROR: ", err);
      });

    $("#calculator").on("input", "input", function(e) {
      const val = $(this).val();
      if (val > 10000) {
        $(this).val(10000);
      }
      const arrTemp = val.split(new RegExp("[,.]", "g"));
      arrTemp.length > 1 &&
        arrTemp[1].length > 2 &&
        $(this).val(parseFloat(arrTemp[0] + "." + arrTemp[1].slice(0, 2)));
    });

    $("#calculator button").click(function(e) {
      const total =
        calcAmount("999") +
        calcAmount("916") +
        calcAmount("900") +
        calcAmount("750") +
        calcAmount("585") +
        calcAmount("333");
      $("#calculator-total").html(total.toFixed(2) + " €");
    });
  }

  $("#button-nav-show").click(function(e) {
    $(this).toggleClass("is-active");
    $("nav ul#nav").toggleClass("hidden");
    $("#action-submenu-ankauf").removeClass("active");
    $("#nav-ankauf").removeClass("visible");
    $("#action-submenu-verkauf").removeClass("active");
    $("#nav-verkauf").removeClass("visible");
  });

  $("#nav-contact").click(function(e) {
    if (ActPage === "index.php") {
      if (window.innerWidth < 768) {
        $("#button-nav-show").toggleClass("is-active");
        $("nav ul#nav").toggleClass("hidden");
      }
      zenscroll.to(ContactElem);
    } else {
      window.location = "index.php#contact-anchor";
    }
  });
  $("#nav-prices").click(function(e) {
    if (ActPage === "index.php") {
      if (window.innerWidth < 768) {
        $("#button-nav-show").toggleClass("is-active");
        $("nav ul#nav").toggleClass("hidden");
      }
      zenscroll.center(PricesElem, 500, 300);
      $("#nav-ankauf").removeClass("visible");
    } else {
      window.location = "index.php#prices-anchor";
    }
  });
  $("#nav-calc").click(function(e) {
    if (ActPage === "index.php") {
      if (window.innerWidth < 768) {
        $("#button-nav-show").toggleClass("is-active");
        $("nav ul#nav").toggleClass("hidden");
      }
      zenscroll.center(CalcElem, 500, 300);
      $("#nav-ankauf").removeClass("visible");
    } else {
      window.location = "index.php#calculator-anchor";
    }
  });

  $("#action-submenu-ankauf").click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).toggleClass("active");
    $("#nav-ankauf").toggleClass("visible");
    $("#action-submenu-verkauf").removeClass("active");
    $("#nav-verkauf").removeClass("visible");
  });

  $("#action-submenu-verkauf").click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).toggleClass("active");
    $("#nav-verkauf").toggleClass("visible");
    $("#action-submenu-ankauf").removeClass("active");
    $("#nav-ankauf").removeClass("visible");
  });

  document.addEventListener("scroll", function(e) {
    var posY = window.pageYOffset;
    if (posY > 1000) {
      $(BranchElem).show();
      $(HeaderElem).hide();
    } else {
      $(BranchElem).hide();
      $(HeaderElem).show();
    }
    if (posY > 100) {
      $("nav").addClass("sticky");
    }
    if (posY < 100) {
      $("nav").removeClass("sticky");
    }
  });

  $("#button-scrollto-content").click(function(e) {
    zenscroll.center(ContentElem, 500, 200);
  });

  $("#button-scrollto-prices").click(function(e) {
    zenscroll.center(PricesElem, 500, 300);
  });

  $("#button-scrollto-calculator").click(function(e) {
    zenscroll.center(CalcElem, 500, 200);
  });

  // $("#button-scrollto-contactform").click(function(e) {
  //   zenscroll.to(ContactElem);
  // });

  $("#button-scrollto-contactform").click(function(e) {
    zenscroll.to(ContactElem);
  });

  $("#button-scrollto-openingtime").click(function(e) {
    zenscroll.to(ContactButtonElem);
  });

  $("#button-scrollto-top").click(function(e) {
    zenscroll.toY(0);
  });

  $("#scroll-to-carat").click(function(e) {
    zenscroll.to(document.getElementById("carat"));
  });

  $("#scroll-to-cut").click(function(e) {
    zenscroll.to(document.getElementById("cut"));
  });

  $("#scroll-to-color").click(function(e) {
    zenscroll.to(document.getElementById("color"));
  });

  $("#scroll-to-clarity").click(function(e) {
    zenscroll.to(document.getElementById("clarity"));
  });

  $.get(
    "https://www.quandl.com/api/v3/datasets/LBMA/GOLD.json?start_date=" +
      getDateBefore() +
      "&end_date=" +
      getTodayDate() +
      "&api_key=WGwsaTPyydyfxPZ1SZih",
    function(data) {
      const index = isAM() === true ? 5 : 6;
      $("#box-gold .value").text(data.dataset.data[0][index].toFixed(2));
    }
  );
  $.get(
    "https://www.quandl.com/api/v3/datasets/LBMA/SILVER.json?start_date=" +
      getDateBefore() +
      "&end_date=" +
      getTodayDate() +
      "&api_key=WGwsaTPyydyfxPZ1SZih",
    function(data) {
      $("#box-silver .value").text(data.dataset.data[0][3].toFixed(2));
    }
  );

  function isAM() {
    return new Date().getHours() < 12 ? true : false;
  }

  function getDateBefore() {
    var dateTemp = new Date();
    dateTemp.setDate(dateTemp.getDate() - 5);
    return (
      dateTemp.getFullYear() +
      "-" +
      (dateTemp.getMonth() + 1) +
      "-" +
      dateTemp.getDate()
    );
  }

  function getTodayDate() {
    var dateTemp = new Date();
    return (
      dateTemp.getFullYear() +
      "-" +
      (dateTemp.getMonth() + 1) +
      "-" +
      dateTemp.getDate()
    );
  }

  // CONTACT FORM START

  $("#button-send-contactdata").click(function(event) {
    event.preventDefault();

    var contactData = {};
    const contactName = $("form #input-name").val();
    const contactMail = $("form #input-mail").val();
    const contactTel = $("form #input-tel").val();
    const contactTitle = $("form #input-title").val();
    const contactMessage = $("form #input-message").val();

    var check = 0;

    contactData["name"] = contactName;
    contactData["mail"] = contactMail;
    contactData["tel"] = contactTel;
    contactData["title"] = contactTitle;
    contactData["message"] = contactMessage;

    if (contactName.length > 3 && contactName.length < 30) {
      check++;
      $("form #input-name").removeClass("alert");
    } else {
      $("form #input-name").addClass("alert");
    }
    if (contactMail.length > 8 && contactMail.length < 30) {
      check++;
      $("form #input-mail").removeClass("alert");
    } else {
      $("form #input-mail").addClass("alert");
    }
    if (contactTel.length > 8 && contactTel.length < 20) {
      check++;
      $("form #input-tel").removeClass("alert");
    } else {
      $("form #input-tel").addClass("alert");
    }
    if (contactTitle.length > 3 && contactTitle.length < 50) {
      check++;
      $("form #input-title").removeClass("alert");
    } else {
      $("form #input-title").addClass("alert");
    }
    if (contactMessage.length > 3 && contactMessage.length < 200) {
      check++;
      $("form #input-message").removeClass("alert");
    } else {
      $("form #input-message").addClass("alert");
    }
    if (check === 5) {
      sendContactData(contactData);
    } else {
      alert("Bitte die Eingaben in den rot markierten Feldern überprüfen!");
    }
  });

  function resetContactData() {
    $("form input").removeClass("alert");
    $("form #input-name").val("");
    $("form #input-mail").val("");
    $("form #input-tel").val("");
    $("form #input-title").val("");
    $("form #input-message").val("");
  }

  function sendContactData(contactData) {
    $.ajax({
      url: "php/contact.php",
      type: "POST",
      data: contactData,
      success: function(data) {
        alert(data);
        resetContactData();
      },
      error: function() {
        alert("Daten senden fehlgeschlagen!");
      }
    });
  }
});
